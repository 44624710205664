import React, { Component } from "react";
import FracturedCellsContract from "./contracts/FracturedCells.json";

import Web3 from "web3";
import Web3Modal from "web3modal";
import convert from 'ether-converter';

import Connect from "./components/Connect";
import NumMinted from "./components/NumMinted";
import Minter from "./components/Minter";

import "./App.css";
import "./fcells.css"

const TRANSACTION_STATUS = {
  PENDING_CREATION: "PENDING_CREATION",
  FAILED: "FAILED",
  CREATED: "CREATED",
  IDLE: "IDLE",
};

const providerOptions = {
  /* See Provider Options Section */
};

const web3Modal = new Web3Modal({
  cacheProvider: true, // optional
  providerOptions // required
});

const sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const DEFAULT_NUM_MINT_STRING = '????';
const DEFAULT_MAX_CELLS = 1500;
const DEFAULT_STATE = { 
  web3: null, 
  accounts: null, 
  contract: null,
  waitingForConnection: false,
  badChain: false,
  transactionStatus: TRANSACTION_STATUS.IDLE,
  statusMessage: "",
  contractState: {
    maxFracturedCells: DEFAULT_MAX_CELLS,
    numMinted: DEFAULT_NUM_MINT_STRING,
    publicMintStarted: null,
    price: null,
  }
};

class App extends Component {
  state = DEFAULT_STATE;

  componentDidMount = async () => {
    if (web3Modal.cachedProvider) {
      await this.connect();
    }
  }

  formatNumMinted = (numMinted) => {
    const numMintedString = numMinted ? String(numMinted) : '0';
    const numDigits = numMintedString.length;
    const emptyChars = new Array(4 - numDigits).fill('0');

    return `${emptyChars.join('')}${numMintedString}`
  }

  onFracturedCellCreated = (event) => {
    if (event.returnValues.tokenId > 0) {
      this.setState({
        contractState: {
          ...this.state.contractState,
          numMinted: this.formatNumMinted(event.returnValues.tokenId),
        }
      });
    }
  }

  onPublicMintStarted = (event) => {
    this.setState({
      contractState: {
        ...this.state.contractState,
        publicMintStarted: true,
      }
    });
  }

  updateContractState = async () => {
    if (this.state.web3 && this.state.contract) {
      const numMinted = await this.state.contract.methods.numFracturedCells.call().call().catch(() => null);
      const publicMintStarted = await this.state.contract.methods.publicMintStarted.call().call().catch(() => false);
      const maxFracturedCells = await this.state.contract.methods.maxFracturedCells.call().call().catch(() => DEFAULT_MAX_CELLS);
      const price = await this.state.contract.methods.price.call().call().catch(() => null);

      this.setState({
        contractState: {
          numMinted: numMinted ? this.formatNumMinted(numMinted) : DEFAULT_NUM_MINT_STRING,
          publicMintStarted,
          maxFracturedCells,
          price: convert(price, 'wei', 'ether'),
        }
      });
    }
  }

  setupEventListeners = async () => {
    if (this.state.web3 && this.state.contract) {
      this.state.contract.events.onFracturedCellCreated()
      .on('data', event => {
        this.onFracturedCellCreated(event);
      });

      this.state.contract.events.onPublicMintStarted()
      .on('data', event => {
        this.onPublicMintStarted();
      });
    }
  }

  handleDisconnect = async () => {
    web3Modal.clearCachedProvider();
    this.setState(DEFAULT_STATE);
  }

  connect = async () => {
    this.setState({
      waitingForConnection: true
    });

    const provider = await web3Modal.connect().catch((error) => {
      this.setState({
        waitingForConnection: false
      });
      console.log("failed to connect", error);
    });

    if (provider) {
      const chainId = Number(provider.chainId.split('0x')[1]);
      this.setState({
        chainId: chainId,
        waitingForConnection: false
      });

      // Subscribe to accounts change
      provider.on("accountsChanged", (accounts) => {
        if (accounts.length === 0) {
          this.handleDisconnect();
        }

        this.setState({account: accounts[0]});
      });

      // Subscribe to chainId change
      provider.on("chainChanged", (chainId) => {
        this.setState({
          chainId: chainId,
          badChain: chainId == 1 ? false : true,
          waitingForConnection: false
        }, () => this.connect());
      });

      // Subscribe to provider connection
      provider.on("connect", (info) => {
      });

      // Subscribe to provider disconnection
      provider.on("disconnect", (error) => {
        this.handleDisconnect();
      });

      if (chainId !== 1) {
        this.setState({
          chainId: chainId,
          badChain: true,
        });  
        return;
      }


      const web3 = new Web3(provider);
      const networkId = await web3.eth.net.getId();
      const deployedNetwork = FracturedCellsContract.networks[networkId];

      // Use web3 to get the user's accounts.
      const accounts = await web3.eth.getAccounts();
      const contract = new web3.eth.Contract(
        FracturedCellsContract.abi,
        deployedNetwork && deployedNetwork.address,
      );

      this.setState({web3, account: accounts[0], contract});
      this.updateContractState();
      this.setupEventListeners();
    }
  }

  mint = async (num) => {
    if (this.state.contract && this.state.account && this.state.web3 && this.state.contractState.price) {
      const price = num * this.state.contractState.price;

      this.setState({
        transactionStatus: TRANSACTION_STATUS.PENDING_CREATION,
        statusMessage: "Transaction pending",
      });

      await this.state.contract.methods.create(num).send({
        from: this.state.account, 
        value: this.state.web3.utils.toWei(String(price), "ether")
      })
      .then(() => {
        this.setState({
          transactionStatus: TRANSACTION_STATUS.CREATED,
          statusMessage: "Mint success",
        });
      })
      .then(async () => {
        await sleep(1000);
        this.setState({
          transactionStatus: TRANSACTION_STATUS.IDLE,
          statusMessage: "",
        });
      })
      .catch((e) => {
        this.setState({
          transactionStatus: TRANSACTION_STATUS.FAILED,
          statusMessage: "Mint cancelled/failed",
        });
      });
    }
  }

  render() {
    if (this.state.badChain && this.state.chainId !== 1) {
      return (
        <div className="styled box">
          <h1 className="mintHeadingExtras" style={{width:'100%'}}>Error</h1>
          <div style={{fontSize: '1.4em', width: '100%'}}>
            You are not connected to the Ethereum mainnet.
          </div>
        </div>
      );
    } else {
      return (
        <div>
          {!this.state.web3
            ? (
              <Connect
                onClick={this.connect}
                waitingForConnection={this.state.waitingForConnection}
              />
            )
            : (
              <>
                <NumMinted
                  numMintedString={this.state.contractState.numMinted}
                />
                <Minter
                  transactionStatus={this.state.transactionStatus}
                  contractState={this.state.contractState}
                  chainId={this.state.chainId}
                  mint={this.mint}
                />
              </>
            )
          }
        </div>
      );
    }
  }
}

export default App;
