import React, { Component } from "react";
export default class Connect extends Component {

  render() {
    return (
      <div 
        className="connect styledinverse" 
        style={{marginTop:'0.5em', marginBottom:'0.5em'}}
        onClick={this.props.onClick}
      >
        Mint
      </div>
    );
  }
}
