import React, { Component } from "react";



export default class NumMinted extends Component {
  
  render() {
    const progresstext = this.props.numMintedString
    const progress = (parseFloat(progresstext, 10) / 1500) * 100
    const progresscss = progress + '%'

    return (
      <div className="styled box minted">
        <h2>{`Fractured Cells Minted: ${this.props.numMintedString}/1500`}</h2>
        <div class="mintbar">
          <div class="mintbarprog" style={{width: progresscss}}></div>
        </div>
      </div>
    );
  }
}
