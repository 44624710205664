import React, { Component } from "react";

import Mint from './Mint';

import spinner from "../img/loading_bw.gif";

const DEFAULT_STATE = {
  numMint: 1,
};

const STAGES = {
  INITIALIZATION: "INITIALIZATION",
  MINTING_NOT_STARTED: "MINTING_NOT_STARTED",
  MINTING_STARTED: "MINTING_STARTED",
  MINTING_FINISHED: "MINTING_FINISHED",
}

const MINT_DATE = new Date(1633402800000);

export default class Minter extends Component {
  state = DEFAULT_STATE;

  getStage = () => {
    const state = this.props.contractState;
    if (state.publicMintStarted === null) return STAGES.INITIALIZATION;
    if (state.numMinted === state.maxFracturedCells) return STAGES.MINTING_FINISHED;
    return state.publicMintStarted ? STAGES.MINTING_STARTED : STAGES.MINTING_NOT_STARTED;
  }

  handleNumMint = (event) => {
    let value = event.target.value;

    if (value < 0) value = 0;
    if (value > 10) value = 10;

    this.setState({
      numMint: value,
    });
  }

  render() {
    switch (this.getStage()) {
      case STAGES.INITIALIZATION: {
        return (<div></div>)
      }

      case STAGES.MINTING_STARTED: {
        return (
          <div className="styled box mintbox">
            <div class="left">
              <h1 style={{width: '100%'}} className="mintHeadingExtras">Minting is Active</h1>

              <p style={{fontSize: '1.4em', width: '100%', marginBottom: '1em'}}>
                Up to 10 Fractional Cells can be minted in one transaction.
              </p>

              <form>
                <label><h2>Price: <span className="mintPrice">{`${this.props.contractState.price} Eth Each`}</span></h2></label><br />
                <label><h2>Qty:</h2></label>
                <input type="number" pattern="\d*" id="quantity" min="1" max="10" value={this.state.numMint} onChange={this.handleNumMint}/>
              </form>
              <div>
                <Mint onClick={() => this.props.mint(this.state.numMint)} />
              </div>
              { this.props.transactionStatus === "PENDING_CREATION" && (<div>
                <img
                  src={spinner}
                  style={{
                    width:'auto',
                    height:'4em',
                    marginTop:'1.2em',
                    float: 'right',
                  }}
                />
              </div>)}
            </div>
            <div class="right">
              <img id="placeholder" src="/site/templates/img/placeholder2.gif" alt=""/>
            </div>
          </div>
        );
      }

      case STAGES.MINTING_NOT_STARTED: {
        return (
          <div className="styled box">
            <h1 className="mintHeadingExtras" style={{width:'100%'}}>Minting Starts Soon</h1>
            <div style={{fontSize: '1.4em', width: '100%'}}>
              Fractured Cells will begin minting on {MINT_DATE.toLocaleString('en-US', { hour12: true })}.
            </div>
          </div>
        );
      }

      case STAGES.MINTING_FINISHED: {
        return (
          <div className="styled box">
            <h1 className="mintHeadingExtras" style={{width:'100%'}}>Sold Out</h1>
            <div style={{fontSize: '1.4em', width: '100%'}}>
              Fractured Cells are sold out, you can find them on <a href="https://opensea.io/collection/fractured-cells">Opensea</a>.
            </div>
          </div>
        );
      }
    }
  }
}
