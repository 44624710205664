import React, { Component } from "react";
export default class Connect extends Component {

  onClick = () => {
    if (!this.props.waitingForConnection) {
      this.props.onClick();
    }
  }

  render() {
    return (
      <div className="connect styled" onClick={this.onClick}>
        {
          this.props.waitingForConnection 
          ? "Waiting for Connection"
          : "Connect Metamask"
        }
      </div>
    );
  }
}
